import Layout from "../components/auth/layout.js";
import ErrorPage from "../pages/errorpage.js";
import { redirect } from "react-router-dom";
import persistedState from "../persited_store.js";
import utilityfun from "../helpers/utilityfun.js";
const Login = () => import("../pages/auth/login.js");
const otpVerification = () => import("../pages/auth/otpVerification.js");
const Meeting = () => import("../pages/meeting/index.js")
const Register = () => import("../pages/auth/register.js");
const stripeSignUp = () => import("../pages/auth/stripeSignup.js");
const CleverRegister = () => import("../pages/auth/cleverRegister.js");
const LoginRedirect = () => import("../pages/auth/loginAsOtherRole.js");
const CleverRedirect = () => import("../pages/auth/cleverRedirect.js");
const ZoomRedirect = () => import("../pages/auth/zoomRedirect.js");
const ForgotPasword2 = () => import("../pages/auth/forgotPassword.js");
const ResetPassword = () => import("../pages/auth/resetPassword.js");
const DashBoard = () => import("../pages/classroom/dashboard.js");
const Strands = () => import("../pages/strand/strands.js");
const ReportStudent = () => import("../pages/report/reportStudent.js");
const MoveRequest = () => import("../pages/moveRequest/moveRequest.js");
const ReportClassroom = () => import("../pages/report/reportClassroom.js");
const LearningPlan = () => import("../pages/unAssign/unAssignLearning.js");
const UnAssignPreTest = () => import("../pages/unAssign/unAssignPreTest.js");
const CreateClassRoom = () => import("../pages/classroom/createClassroom.js");
const StrandSubject = () => import("../pages/strand/strand-subject.js");
const addStrands = () => import("../pages/strand/addStrands.js");
const QuestionInstruction = () =>
  import("../pages/strand/question-instruction.js");
const ClassRoomStudents = () => import("../pages/classroom/students.js");
const AddStudents = () => import("../pages/classroom/addStudents.js");
const ViewProfile = () => import("../pages/auth/viewProfile.js");
const StudentLP = () => import("../pages/report/studentlp.js");
const StrandReport = () => import("../pages/report/strandReport.js");
const TopicReport = () => import("../pages/report/topicReport.js");
const QuestionReport = () => import("../pages/report/questionReport.js");
const AssignPreTestLP = () => import("../pages/classroom/assignPretestLp.js");
const PretestReport = () => import("../pages/report/pretestReport.js");
const GradeWiseReport = () => import("../pages/report/gradeWiseReport.js");
const CompleteLearningPlan = () =>
  import("../pages/classroom/completeLearningPlan.js");
const CompleteReport = () => import("../pages/report/completeReport.js");
const PretestQuestion = () => import("../pages/report/pretestQuestion.js");
const AdminDashBoard = () => import("../pages/dashboard");
const Teachers = () => import("../pages/userStatus/teacher.js");
const Students = () => import("../pages/userStatus/student.js");
const Parents = () => import("../pages/userStatus/parent.js");
const Relationships = () => import("../pages/userStatus/relationship.js");
const AccountDeletion = () => import("../pages/userStatus/account-deletion.js");
const AvailableStudents = () => import("../pages/report/allStudents.js");
const UploadExcel = () => import("../pages/classroom/uploadUsers.js");
const notStartedStudentActivity = () =>
  import("../pages/report/studentActivity/notStartedYet.js");
const inActiveStudentActivity = () =>
  import("../pages/report/studentActivity/inactiveStudents.js");
const dailyStudentSession = () =>
  import("../pages/report/studentSession/daily.js");
const teacherQuickReport = () =>
  import("../pages/report/teacherReport/quick.js");
const Subscriptions = () => import("../pages/subscription.js");
const MoveStudent = () => import("../pages/moveRequest/moveStudent.js");
const MoveManually = () => import("../pages/moveRequest/moveManually.js");
const monthlyStudentSession = () =>
  import("../pages/report/studentSession/monthly.js");

const ParentDashBoard = () => import("../pages/parent/index.js");
const ParentClassroom = () => import("../pages/parent/classroom.js")
const ParentAddChild = () => import("../pages/parent/addChild.js")

/*  public pages */
const Home = () => import("../pages/publicpages/home.js");
const About = () => import("../pages/publicpages/about.js");
const Privacy = () => import("../pages/publicpages/privacy.js");
const Terms = () => import("../pages/publicpages/terms.js");
const TermsPolicy = () => import("../pages/publicpages/termspolicy.js");
const Support = () => import("../pages/publicpages/support.js");
const createDistrict = () => import("../pages/districts/createDistrict.js");
const districts = () => import("../pages/districts");
const subjects = () => import("../pages/strand/subjects.js")
const ScheduleMeeting = () => import("../pages/meeting/scheduleMeeting.js");
const ReScheduleMeeting = () => import("../pages/meeting/rescheduleMeeting.js");
const meeting_details = () => import("../pages/meeting/meeting_details.js");
const UploadQuestion = () => import("../pages/strand/uploadQuestions.js");
const AddSubject = () => import("../pages/strand/addSubject.js");
const UploadUsers = () => import("../pages/auth/uploadUsers.js");
const TeacherStudents = () => import("../pages/dashboard/teacherstudents.js");
/*  check auth if exist redirect to dashboard */

const checkAuth = () => {
  if (utilityfun.isTeacher()) {
    return redirect("/account/dashboard");
  } else if (utilityfun.isStudent()) {
    window.location.replace("/student/dashboard");
    return null; 
  } else if (utilityfun.isAdmin() || utilityfun.isSchoolAdmin() || utilityfun.isDistrictAdmin()) {
    return redirect("/admin/dashboard");
  } else if (utilityfun.isCoordinator()) {
    return redirect("/coordinator/classrooms");
  }
  return null; 
}
/*  check auth not exist redirect to login */
const notExistAuth = () => (!persistedState.token() ? redirect("/login") : "");
const routes = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    loader: checkAuth,
    children: [
      { index: true, lazy: Home },
      {
        path: "home",
        lazy: Home,
      },
      {
        path: "about",
        lazy: About,
      },
      {
        path: "privacy",
        lazy: Privacy,
      },
      {
        path: "terms",
        lazy: Terms,
      },
      {
        path: "termspolicy",
        lazy: TermsPolicy,
      },
      {
        path: "support",
        lazy: Support,
      },
      {
        path: "login",
        lazy: Login,
      },
      {
        path: "otp-verify/:userId",
        lazy: otpVerification,
      },
      {
        path: "register",
        lazy: Register,
        loader: () => utilityfun.getSubDomain() == "global" ? redirect("/signup") : ""
      },
      // {
      //   path: "clever-register",
      //   lazy: CleverRegister,
      // },
      {
        path: "redirect",
        lazy: CleverRedirect,
      },
      {
        path: "clever_user_verify",
        lazy: CleverRegister,
      },
      {
        path: "redirect-login",
        lazy: LoginRedirect,
      },
      {
        path: "zoom-redirect",
        lazy: ZoomRedirect,
      },
      {
        path: "forgot-password",
        lazy: ForgotPasword2,
      },
      {
        path: "reset-password/:token",
        lazy: ResetPassword,
      },
      {
        path: "signup",
        lazy: stripeSignUp,
        loader: () => utilityfun.getSubDomain() != "global" ? redirect("/login") : ""
      },
    ]
  },
  {
    path: "account",
    lazy: () => import("../components/dashboardLayout.js"),
    loader: notExistAuth,
    errorElement: <ErrorPage />,
    children: [
      { index: true, lazy: DashBoard },
      {
        path: "dashboard",
        lazy: DashBoard,
        name: "Classrooms",
      },
      {
        path: "students",
        lazy: TeacherStudents,
        name: "Students",
      },
      {
        path: "assign-pretest-lp",
        lazy: AssignPreTestLP,
        name: "Assign Learning Plan",
      },
      {
        path: "strands/classroom",
        lazy: Strands,
        name: "Strands",
      },
      {
        path: "/account/upload-excel/:type",
        lazy: UploadExcel,
        name: "Upload Excel",
      },
      {
        path: "strands/subject/:id",
        lazy: StrandSubject,
        name: "Strands",
      },
      {
        path : "strands/addStrands/:id",
        lazy: addStrands,
        name: "Add Strands",
      },
      {
        path: "strands/question-instruction/:id",
        lazy: QuestionInstruction,
        name: "Topic - Questions and Instructions",
      },
      {
        path: "reports/student",
        lazy: ReportStudent,
        name: "Reports",
      },
      {
        path: "reports/student-lp/:classroomId/:studentId",
        lazy: StudentLP,
        name: "Learning Plan Wise Student Report",
      },
      {
        path: "reports/strand/:classroomId/:studentId/:batchId",
        lazy: StrandReport,
        name: "Strand Wise Student Report",
      },
      {
        path: "reports/topic/:classroomId/:studentId/:strandId/:ucs/:batchId",
        lazy: TopicReport,
        name: "Topic Wise Student Report",
      },
      {
        path: "reports/question/:classroomId/:studentId/:strandId/:topicId/:ucs/:batchId",
        lazy: QuestionReport,
        name: "Question Wise Student Report",
      },
      {
        path: "reports/classroom/:classroomId?",
        lazy: ReportClassroom,
        name: "Classroom Wise Detailed Report",
      },
      {
        path: "reports/resetplan",
        lazy: LearningPlan,
        name: "Un-Assign Learning Plan",
      },
      {
        path: "reports/resetPretest",
        lazy: UnAssignPreTest,
        name: "Un-Assign Pre-Test",
      },
      {
        path: "move-requests/:teacherId?",
        lazy: MoveRequest,
        name: "Move Student",
      },
      {
        path: "createClassRoom",
        lazy: CreateClassRoom,
        name: "Create Classroom",
      },
      {
        path: "edit-classroom/:id",
        lazy: CreateClassRoom,
        name: "Edit Classroom",
      },
      {
        path: "students/:id",
        lazy: AddStudents,
        name: "Students",
      },
      {
        path: "classroom-students/:classroomId",
        lazy: ClassRoomStudents,
        name: "Classroom",
      },
      {
        path: "viewProfile",
        lazy: ViewProfile,
        name: "View Profile",
      },
      {
        path: "reports/pretest/:classroomId/:studentId",
        lazy: PretestReport,
        name: "Pre-Test Student Report",
      },
      {
        path: "reports/pretest-grades/:classroomId/:studentId",
        lazy: GradeWiseReport,
        name: "Grade Wise Pre-Test Report",
      },
      {
        path: "current-lp/:classroomId/:studentId",
        lazy: CompleteLearningPlan,
        name: "Completed Learning Plan",
      },
      {
        path: "past-lp/:classroomId/:studentId",
        lazy: CompleteLearningPlan,
        name: "Current Learning Plan",
      },
      {
        path: "reports/pretestQuestion/:classroomId/:studentId/",
        lazy: PretestQuestion,
        name: "Pre-Test Questions",
      },
      {
        path: "reports/complete/:classroomId/:studentId",
        lazy: CompleteReport,
        name: "Student Progress Report Summary",
      },
    ],
  },
  {
    path: "admin",
    lazy: () => import("../components/dashboardLayout.js"),
    loader: notExistAuth,
    errorElement: <ErrorPage />,
    children: [
      { index: true, lazy: AdminDashBoard },
      {
        path: "dashboard",
        lazy: AdminDashBoard,
        name: "Classrooms",
      },
      {
        path: "available-students",
        lazy: AvailableStudents,
        name: "All Available Students",
      },
      {
        path: "not-started",
        lazy: notStartedStudentActivity,
        name: "Students Not Yet Started",
      },
      {
        path: "inactive-students",
        lazy: inActiveStudentActivity,
        name: "Inactive Students",
      },
      {
        path: "daily-student-session",
        lazy: dailyStudentSession,
        name: "Student Session Report Day Wise",
      },
      {
        path: "monthly-student-session",
        lazy: monthlyStudentSession,
        name: "Student Session Report Month Wise",
      },
      {
        path: "teacher-quick-report",
        lazy: teacherQuickReport,
        name: "Teacher Quick Report",
      },
      {
        path: "teachers",
        lazy: Teachers,
        name: "Teachers User Status",
      },
      {
        path: "students-user-status",
        lazy: Students,
        name: "Students User Status",
      },
      {
        path: "parents",
        lazy: Parents,
        name: "Parent User Status",
      },
      {
        path: "relationships",
        lazy: Relationships,
        name: "Relationships User Status",
      },
      {
        path: "upload-questions",
        lazy: UploadQuestion,
        name: "Upload Questions",
      },
      {
        path: "account-deletion",
        lazy: AccountDeletion,
        name: "Account Deletion",
      },
      {
        path: "subscriptions",
        lazy: Subscriptions,
        name: "Subscriptions",
      },
      {
        path: "movestudent",
        lazy: MoveStudent,
        name: "Move Student",
      },
      {
        path: "move-manually",
        lazy: MoveManually,
        name: "Move Manually",
      },
      {
        path: "districts",
        lazy: districts,
        name: "Districts",
      },
      {
        path: "create-district",
        lazy: createDistrict,
        name: "Create District",
      },
      {
        path: "edit-district/:districtId",
        lazy: createDistrict,
        name: "Edit District",
      },
      {
        path: "addSubject",
        lazy: AddSubject,
        name: "Add Subject",
      },
      {
        path: "Subjects",
        lazy: subjects,
        name: "Subjects",
      },
      {
        path: "upload-users",
        lazy: UploadUsers,
        name: "Upload Users",
      },
    ],
  },
  {
    path: "parent",
    lazy: () => import("../components/dashboardLayout.js"),
    loader: notExistAuth,
    errorElement: <ErrorPage />,
    children: [
      { index: true, lazy: ParentDashBoard },
      {
        path: "dashboard",
        lazy: ParentDashBoard,
        name: "Childrens",
      },
      {
        path: "classrooms/:studentId",
        lazy: ParentClassroom,
        name: "Classrooms",
      },
      {
        path: "addchild",
        lazy: ParentAddChild,
        name: "Add Child",
      },
    ]
  },
  {
    path: "coordinator",
    lazy: () => import("../components/dashboardLayout.js"),
    loader: notExistAuth,
    errorElement: <ErrorPage />,
    children: [
      { index: true, lazy: Meeting },
      {
        path: 'classrooms',
        lazy: Meeting,
        name: "Meetings"
      },
      {
        path: 'meeting/:id',
        lazy: meeting_details,
        name: "Meeting Detail"
      },
      {
        path: "schedule-meeting",
        lazy: ScheduleMeeting,
        name: "Schedule Classroom",
      },
      {
        path: "re-schedule-meeting/:id",
        lazy: ReScheduleMeeting,
        name: "Re-Schedule / Edit Classroom",
      },
    ],
  },
];

export default routes;
